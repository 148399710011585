import axios from "axios";
const global = require("../config/config");

const send2Mail = async (campos, infoJson) => {
  try {
    var options = {
      method: "POST",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/mail/enviar2Email/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: {
        "Content-Type": "application/json",
        token: process.env.REACT_APP_TOKEN_API,
      },
      data: campos,
    };

    return await axios
      .request(options)
      .then((error) => {
        return error;
      })
      .catch(function (error) {
        console.error(error);
      });
  } catch (error) {
    console.error("error", error);
  }
};

const sendMail = async (campos, infoJson) => {
  try {
    var options = {
      method: "POST",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/mail/enviarEmail/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: {
        "Content-Type": "application/json",
        token: process.env.REACT_APP_TOKEN_API,
      },
      data: campos,
    };

 

    return await axios
      .request(options)
      .then((error) => {
        return error;
      })
      .catch(function (error) {
        console.error(error);
      });
  } catch (error) {
    console.error("error", error);
  }
};

export { sendMail, send2Mail };
