import React, { createContext, useReducer } from "react";

// declare AuthContext as a Context object
export const AuthContext = createContext();

const initialState = {
  Segmentos: [],
  allinfo: [],
  infoPrincipal: [],
  userToken: null,
};

// reducer to manage auth state
const reducer = (prevState, action) => {
  switch (action.type) {
    case "AddSegmentos":
      return {
        ...prevState,
        Segmentos: action.list,
      };
 

    case "AddInfo":
      return {
        ...prevState,
        allinfo: action.list,
      };
    case "AddPrincipal":
      return {
        ...prevState,
        infoPrincipal: action.list,
      };
    default:
      return initialState;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
