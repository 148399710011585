import React, { useState, useEffect } from "react";
import Rotas from "./components/routes/Rotas";
import * as apiRent from "./api/apiRent";
import * as apiEmpresa from "./api/apiEmpresa";
import * as apiJson from "./api/apiJson";
import useScriptWidgetsWhats from "./components/hooks/useScriptWidgetsWhats";
import useGaTracker from "./components/hooks/useGaTracker";

import Siteholder from "./components/tools/placeholder/Siteholder";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "styled-components";
import ReactSEOMetaTags from "react-seo-meta-tags";
import { lightTheme, darkTheme } from "./config/theme";
import GlobalTheme from "./config/global";
import CookieConsentir from "./components/tools/cookies/CookieConsentir";
import Snow from "./components/tools/snowFall/Snow";
import AuthContext from "./components/hooks/AuthContext";
import { ordenarSegmentos } from "./components/helpers/tratarinfoRent";

export default function App() {
  const { state, dispatch } = React.useContext(AuthContext);
  const [infoJson, setInfoJson] = useState([]);
  const [metaTags, setMetaTags] = useState([]);
  const [allinfoText, setAllinfoText] = useState([]);

  useEffect(() => {
    const getJson = async () => {
      const info = await apiJson.infoJson();
      setInfoJson(info);
    };

    getJson();
  }, []);
  useEffect(() => {
    const getAllinfoText = async () => {
      const info = await apiEmpresa.getInfoTextos(infoJson);
      setAllinfoText(info);
    };
    Object.keys(infoJson).length !== 0 && getAllinfoText();
  }, [infoJson]);

  useScriptWidgetsWhats(infoJson);
  useGaTracker(infoJson);

  useEffect(() => {
    const getViaturas = async () => {
      const segmentos = await apiRent.listagemSegmentos(infoJson);

      const segmentosfilter = ordenarSegmentos(
        segmentos,
        infoJson.Layout.Segmento.ordenar
      );

      dispatch({ type: "AddSegmentos", list: segmentosfilter });
    };
    if (Object.keys(infoJson).length !== 0) {
      getViaturas();
    }
  }, [infoJson, dispatch]);

  useEffect(() => {
    const getEmpresas = async () => {
      const info = await apiRent.getInfoEmpresa(infoJson);

      dispatch({ type: "AddInfo", list: info });

      const infoPrincipal = info.filter((empresa, index) => {
        if (empresa.Principal === true) {
          return empresa.Principal === true;
        } else {
          return index === 0;
        }
      });

      dispatch({ type: "AddPrincipal", list: infoPrincipal });
    };

    Object.keys(infoJson).length !== 0 && getEmpresas();
  }, [infoJson, dispatch]);

  return (
    <>
      {Object.keys(infoJson).length !== 0 && (
        <>
          <ThemeProvider
            theme={infoJson.Layout.ThemeDark ? darkTheme : lightTheme}
          >
            <GlobalTheme infoJson={infoJson} />
            <HelmetProvider>
              <ReactSEOMetaTags
                render={(el) => (
                  <Helmet>
                    {/* Chrome, Firefox OS and Opera  */}
                    <meta
                      name="theme-color"
                      content={infoJson.Cores.FundoTransparentScroll}
                    />
                    {/* Windows Phone  */}
                    <meta
                      name="msapplication-navbutton-color"
                      content={infoJson.Cores.FundoTransparentScroll}
                    />
                    {/* iOS Safari  */}
                    <meta
                      name="apple-mobile-web-app-status-bar-style"
                      content={infoJson.Cores.FundoTransparentScroll}
                    />
                    {infoJson.Stand.Lead2team.existe && (
                      <script type="text/javascript">
                        {` 
              (function (w,d,s,o,f,js,fjs,mpd) { w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
              mpd = d.createElement('div');
              mpd.id = 'widget_'+o; d.body.appendChild(mpd); js = d.createElement(s), fjs = d.getElementById('widget_'+o); js.id = o; js.src = f; 
              js.async = 1; fjs.parentNode.insertBefore(js, fjs); }(window, document, 'script', 'lead2team', 'https://online.lead2team.com/widget/widget.js'));
              lead2team('init', { apiKey: '${infoJson.Stand.Lead2team.apiKey}' });
              lead2team('lead2team', { groups: '', locations: ''}); 
            `}
                      </script>
                    )}

                    {el}
                  </Helmet>
                )}
                website={metaTags.fullWebsiteData}
                organization={metaTags.organization}
                facebook={metaTags.facebookData}
                twitter={metaTags.twitterData}
                blogPost={metaTags.blogPostData}
              />
            </HelmetProvider>
            {infoJson.Stand.Snowfall.existe && <Snow infoJson={infoJson} />}

            {state.Segmentos.length === 0 ? (
              <>
                <Siteholder infoJson={infoJson} />
              </>
            ) : (
              <>
                <Rotas
                  setMetaTags={setMetaTags}
                  infoJson={infoJson}
                  allinfoText={allinfoText}
                />
              </>
            )}
            <CookieConsentir />
          </ThemeProvider>
        </>
      )}
    </>
  );
}
