import axios from "axios";
const global = require("../config/config");

async function getAcessorios(infoJson) {
  try {
    const API_URL_GETNOTICIAS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/rent/GetAcessorios/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
      },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_GETNOTICIAS);

    if (res.data.length > 0) {
      return res.data;
    }
    return [];
  } catch (error) {
    console.error("getAcessorios - error", error);
    return [];
  }
}

async function listagemSegmentos(infoJson) {
  try {
    const API_URL_GETNOTICIAS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/rent/listagemSegmentos/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
      },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_GETNOTICIAS);

    if (res.data.length > 0) {
      return res.data;
    }
    return [];
  } catch (error) {
    console.error("listagemSegmentos - error", error);
    return [];
  }
}

async function getVerificaAbertura(CodPontoVenda, Concelho, infoJson, data) {
  try {
    const API_URl_HORARIOS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/rent/getVerificaAbertura/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
        stand_id: CodPontoVenda,
        country: Concelho,
        date: data,
      },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URl_HORARIOS);
    const servico = res.data;

    return servico;
  } catch (error) {
    console.log("error", error);
  }
}

async function sendReservarRent(dados, infoJson) {
  try {
    const API_URL_INSERT_SENDDATA = {
      method: "POST",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/rent/reservar/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
      },
      headers: {
        "Content-Type": "application/json",
        token: process.env.REACT_APP_TOKEN_API,
      },
      data: dados,
    };

    return await axios
      .request(API_URL_INSERT_SENDDATA)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error.response.status;
      });
  } catch (error) {
    console.error("error sendReservarRent", error);
  }
}

async function getInfoEmpresa(infoJson) {
  try {
    var API_URL_INFO = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/rent/GetContactosClienteAll/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_INFO);
    const info = res.data;
    return info;
  } catch (error) {
    console.log("error", error);
  }
}

export {
  getAcessorios,
  listagemSegmentos,
  sendReservarRent,
  getVerificaAbertura,
  getInfoEmpresa,
};
