import { createGlobalStyle } from "styled-components";
import { textColor } from "../components/helpers/tratarInfoEmpresa";

export default createGlobalStyle`

body {
  background: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
}

a{
    color: ${({ theme }) => theme.text};  
}

a:hover{
  color: ${({ theme }) => theme.text};  
  text-decoration: none;
}
.SVGColor{
  fill:${({ theme }) => theme.text};  
  stroke:${({ theme }) => theme.text};  
}

.SVGColorIntervalo{
  fill:${({ theme }) => theme.text};  
  stroke:${({ theme }) => theme.text};  
  color:${({ theme }) => theme.text};  
}


.SVGColorPonto{
  fill:#a8a8a8;  
  stroke:#a8a8a8;  
}

.btnLineText{
  fill:${({ theme }) => theme.text};  
  stroke:${({ theme }) => theme.text};
  
  -webkit-transition: all 0.3s;
  transition: all 0.3s;  
}
.btnLineText:hover,
.btnLineText:hover a,
.btnLineText.active{
  fill:${(props) => props.infoJson.Cores.Principal} !important;   
  stroke:${(props) => props.infoJson.Cores.Principal} !important;  
  color: ${(props) => props.infoJson.Cores.Principal} !important; 
}
.rectangle-option {
  border-bottom: 1px solid ${({ theme }) => theme.lineOption};
}



btnLineTextBanner{
  -webkit-transition: all 0.3s;
  transition: all 0.3s;  
}
.btnLineTextBanner:hover,
.btnLineTextBanner:hover a,
.btnLineTextBanner.active{
  fill:${(props) => props.infoJson.Cores.Principal};  
  stroke:${(props) => props.infoJson.Cores.Principal}; 
  color: ${(props) => props.infoJson.Cores.Principal} !important; 
}
.sectionFooter {
    background: ${({ theme }) => theme.boxCar};  
    border: 1.2px solid  ${({ theme }) => theme.border};  
    box-shadow: 0 13px 44px 0 rgba(0,0,0,0.06);
  }

  .boxShadowPares {  
    background-color: ${({ theme }) => theme.boxCar};
    border: 2px solid ${({ theme }) => theme.borderInput};  
  }
  .boxShadowPares.active {  
    border-color: ${(props) => props.infoJson.Cores.Principal};
  }

  .boxShadowPares > label{
    color: ${(props) => props.infoJson.Cores.Principal}; 
    background-color: ${({ theme }) => theme.boxCar}; 
  }


  .boxShadowParesIntercalo {  
    background-color: ${({ theme }) => theme.boxCar};
    border: 2px solid ${({ theme }) => theme.borderInput};  
  }


  .boxShadowParesIntercalo > label{
    color: ${({ theme }) => theme.text};  
    background-color: ${({ theme }) => theme.boxCar}; 
  }

  .btn.focus,
  .btn:focus {
    box-shadow: none !important;
  }

.oficina_icon_div {
  background-color: ${({ theme }) => theme.body};
  border-radius: 7px;
}
  
.box-slider{
  background-color: ${(props) => props.infoJson.Cores.Principal}; 
  color:#FFFFFF; 
}
.nav-link:hover, .nav-link:focus {
  color: ${({ theme }) => theme.text};
}

.box-more-cars{
  background-color:${({ theme }) => theme.bgMore};
}
.listagem_info{
  background-color: ${({ theme }) => theme.boxCar};
  box-shadow: ${({ theme }) => theme.boxShadow};
  -webkit-box-shadow:${({ theme }) => theme.boxShadow};
  border: 1.2px solid  ${({ theme }) => theme.border};
  box-shadow: 0 13px 44px 0 rgba(0, 0, 0, 0.04);
}
.listagem_info_comparar{
  border-color: ${(props) => props.infoJson.Cores.Principal};
}


.box-options{
  background-color: ${({ theme }) => theme.boxCar}; 
}

.modal-header {
  border-bottom: none;
  box-shadow: 0 13px 44px 0 rgba(0,0,0,0.04);
}
.border-top {
  border-top: 2px  solid ${({ theme }) => theme.border} !important;
}

.modal-content {
  background-color: ${({ theme }) => theme.boxCar};
}

.Mais{
  background-color: ${({ theme }) => theme.boxCarMore};
  fill: ${({ theme }) => theme.boxCarMoreDot};
}

.MaisBanner{
  background-color: #242424 ;
  fill: #585858 ;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;  
  
}
.MaisBanner:hover,
.MaisBanner.ative{
  fill:  ${(props) => props.infoJson.Cores.Principal};
  
}
.MaisOpen{
  background-color: transparent;
  fill:  ${({ theme }) => theme.text};
  stroke:  ${({ theme }) => theme.text};
}
.form-select {
  box-shadow: none;
   background-color: ${({ theme }) => theme.boxCar};
  color: ${({ theme }) => theme.text};
   border: 2px solid ${({ theme }) => theme.borderInput};
  border-radius: 30px;
}
.form-control:disabled, .form-control[readonly] {
  background-color: ${({ theme }) => theme.disable};
}
.form-control {
  box-shadow: none;
   background-color: ${({ theme }) => theme.boxCar};
  color: ${({ theme }) => theme.text};
  border: 2px solid ${({ theme }) => theme.borderInput};
  border-radius: 30px;
}

.form-control:focus {
  border-color: ${({ theme }) => theme.border};
   background-color: ${({ theme }) => theme.boxCar};
  box-shadow: ${({ theme }) => theme.boxShadow};
  outline: 0;
  color: ${({ theme }) => theme.text};
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: ${({ theme }) => theme.text};
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: ${({ theme }) => theme.text};
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: ${({ theme }) => theme.text};
}
.extrasLinha{
  border-color: ${({ theme }) => theme.linha};
}
.extras {
  background-color: ${({ theme }) => theme.btnPartilhaBg};
}

.btn-blinear {
  color:  ${({ theme }) => theme.btnPartilhaColor};
  fill:  ${({ theme }) => theme.btnPartilhaColor};
  stroke:  ${({ theme }) => theme.btnPartilhaColor};
  background-color: ${({ theme }) => theme.btnPartilhaBg};
}
.btn-blinear:hover,
.btn-blinear:focus,
.btn-blinear.active {
  background-color: ${({ theme }) => theme.btnPartilhaBgHover};
  color: ${({ theme }) => theme.btnPartilhaBgcolorHover};
  fill:  ${({ theme }) => theme.btnPartilhaBgcolorHover};
  stroke:  ${({ theme }) => theme.btnPartilhaBgcolorHover};
}

.sidebar-box .read-more {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 50px 0 0 0;
  border-bottom: 15px solid ${({ theme }) => theme.body};
  background: -moz-linear-gradient(
    top,
    ${({ theme }) => theme.body}00 0,
    ${({ theme }) => theme.body}cc 35%,
    ${({ theme }) => theme.body}fa 56%,
    ${({ theme }) => theme.body} 100%
  );
  background: -webkit-linear-gradient(
    top,
     ${({ theme }) => theme.body}00 0,
    ${({ theme }) => theme.body}cc 35%,
    ${({ theme }) => theme.body}fa 56%,
    ${({ theme }) => theme.body} 100%
  );
  background: linear-gradient(
    to bottom,
     ${({ theme }) => theme.body}00 0,
    ${({ theme }) => theme.body}cc 35%,
    ${({ theme }) => theme.body}fa 56%,
    ${({ theme }) => theme.body} 100%
  );
}

.sidebar-box .read-more-footer {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 50px 0 0 0;
  border-bottom: 15px solid ${({ theme }) => theme.boxCar};
  background: -moz-linear-gradient(
    top,
    ${({ theme }) => theme.boxCar}00 0,
    ${({ theme }) => theme.boxCar}cc 35%,
    ${({ theme }) => theme.boxCar}fa 56%,
    ${({ theme }) => theme.boxCar} 100%
  );
  background: -webkit-linear-gradient(
    top,
     ${({ theme }) => theme.boxCar}00 0,
    ${({ theme }) => theme.boxCar}cc 35%,
    ${({ theme }) => theme.boxCar}fa 56%,
    ${({ theme }) => theme.boxCar} 100%
  );
  background: linear-gradient(
    to bottom,
     ${({ theme }) => theme.boxCar}00 0,
    ${({ theme }) => theme.boxCar}cc 35%,
    ${({ theme }) => theme.boxCar}fa 56%,
    ${({ theme }) => theme.boxCar} 100%
  );
}

.btn_ficha {
  background-color: ${({ theme }) => theme.boxCar};
  color: ${({ theme }) => theme.text};
  border: 2px solid ${(props) => props.infoJson.Cores.Principal};
  fill:  ${(props) => props.infoJson.Cores.Principal};
}
.btn_ficha:hover {
  background-color: ${(props) => props.infoJson.Cores.Principal}47;
  
}

.form-check-input:checked {
  background-color: ${(props) => props.infoJson.Cores.Principal};
  border-color: ${(props) => props.infoJson.Cores.Principal};
}
.titulo-page {
  color: ${(props) => props.infoJson.Cores.Principal};
}
.cor-titulo{
  color: ${(props) => props.infoJson.Cores.Principal};
}

.btnForm{
  background-color: ${(props) => props.infoJson.Cores.Principal};
  background: -moz-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%, ${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: -webkit-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${(
    props
  ) => props.infoJson.Cores.GradienteInicio}', endColorstr='${(props) =>
  props.infoJson.Cores.GradienteFim}',GradientType=1 );
}

.btnForm::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255,0.12);
  mix-blend-mode: lighten;
  transition: opacity .35s ease-in-out;
  opacity: 0;
}

.btnForm:hover:before {
  opacity: 1;
}

.btnForm:focus:before {
  background-color: rgba(0, 0, 0,0.12);
  opacity: 1;
}

.btnForm:disabled{
  opacity: 0.5 !important;;
}

.badged {  
  background-color: ${(props) => props.infoJson.Cores.TagImg};
}



.Conerbadged{
  border-color: ${(props) =>
    props.infoJson.Cores.TagImg} transparent transparent transparent;
}
.main_color{
  fill: ${(props) => props.infoJson.Cores.Principal};
}
.cookies{
  background-color: ${(props) => props.infoJson.Cores.Principal}e6 !important;
}
.btn-carregar-mais {
  background-color: ${(props) => props.infoJson.Cores.Principal};
  background: -moz-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%, ${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: -webkit-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${(
    props
  ) => props.infoJson.Cores.GradienteInicio}', endColorstr='${(props) =>
  props.infoJson.Cores.GradienteFim}',GradientType=1 );
  
  }
.btn-carregar-mais:hover {
  -moz-box-shadow: inset 0 0 100px 100px rgba(255,255,255,0.12) !important;
  -webkit-box-shadow: inset 0 0 100px 100px rgb(255 255 255 / 12%) !important;
  box-shadow: inset 0 0 100px 100px rgb(255 255 255 / 12%) !important;
}
.btn-carregar-mais:focus {
  -moz-box-shadow: inset 0 0 100px 100px rgba(0,0,0,0.12) !important;
  -webkit-box-shadow: inset 0 0 100px 100px rgb(0 0 0 / 12%) !important;
  box-shadow: inset 0 0 100px 100px rgb(0 0 0 / 12%) !important;
}
.boxPrecoFicha  {
 color: ${(props) => props.infoJson.Cores.Principal}; 
}

.heightBoxTop{
  background: linear-gradient(270deg, ${({ theme }) => theme.border} 0%, ${({
  theme,
}) => theme.boxCar} 100%);
  background: -moz-linear-gradient(270deg, ${({ theme }) =>
    theme.border} 0%, ${({ theme }) => theme.boxCar} 100%);
  background: -webkit-linear-gradient(270deg, ${({ theme }) =>
    theme.border} 0%, ${({ theme }) => theme.boxCar} 100%);
}
.aumentaManu  {
  fill:${(props) => props.infoJson.Cores.Principal};  
  stroke:${(props) => props.infoJson.Cores.Principal}; 
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
  border: 3px solid ${(props) => props.infoJson.Cores.Principal} !important;
}

.thumbs-slider .swiper-slide.swiper-slide-thumb-active {
  border: 3px solid ${(props) => props.infoJson.Cores.Principal} !important;
}

.btnModal  {
  background-color: ${(props) => props.infoJson.Cores.Principal};
  background: ${(props) => props.infoJson.Cores.Principal};
  background: -moz-linear-gradient(left, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%, ${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: -webkit-linear-gradient(left, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: linear-gradient(to right, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${(
    props
  ) => props.infoJson.Cores.GradienteInicio}', endColorstr='${(props) =>
  props.infoJson.Cores.GradienteFim}',GradientType=1 );
  color:${(props) => textColor(props.infoJson.Cores.GradienteInicio)};
  fill:${(props) => textColor(props.infoJson.Cores.GradienteInicio)};
  box-shadow: 0 5px 15px 0px rgb(${(props) =>
    props.infoJson.Cores.RgbShadow} / 40%);
}
.titulo-newsletter{
  color: ${({ theme }) => theme.text};
}
.titulo-Pesquisa{
  background-color: ${(props) => props.infoJson.Cores.Principal};
  background: -moz-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%, ${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: -webkit-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${(
    props
  ) => props.infoJson.Cores.GradienteInicio}', endColorstr='${(props) =>
  props.infoJson.Cores.GradienteFim}',GradientType=1 ); 

}
.box-obrigado{
  background: ${({ theme }) => theme.boxCar};
  box-shadow: ${({ theme }) => theme.boxShadowOb};
	height: 80px;
	line-height: 80px;
  text-align: center;
} 
.colPesquisaMenu{
  background-color: ${({ theme }) => theme.InputPesquisa}; 
  box-shadow: 0 9px 23px 0 rgba(28, 76, 68, 0.13);
}

.colPesquisaMenu .form-control {
  background-color: ${({ theme }) => theme.InputPesquisa}; 
}

.nav-link {
  color: ${({ theme }) => theme.text};
}

.corFundoClaro {
  background-color: ${(props) => props.infoJson.Cores.FundoTransparentScroll}f0;
  box-shadow: 0 8px 44px 0 rgba(184,184,184,0.23);
}

.corFundoClaroBottom {
  background-color: ${({ theme }) => theme.body};
  box-shadow: 0 0 16px 0 rgba(0,0,0,0.1);
}

.textoLimpar{
  color: ${({ theme }) => theme.text};
  stroke: ${({ theme }) => theme.text};
  border: 2px solid ${({ theme }) => theme.borderInput};  
  background-color: ${({ theme }) => theme.boxCar};
}
.btnPesquisa{
  background-color: ${(props) => props.infoJson.Cores.Principal};
  background: -moz-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%, ${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: -webkit-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${(
    props
  ) => props.infoJson.Cores.GradienteInicio}', endColorstr='${(props) =>
  props.infoJson.Cores.GradienteFim}',GradientType=1 );
}
.btnPesquisa::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255,0.12);
  mix-blend-mode: lighten;
  transition: opacity .35s ease-in-out;
  opacity: 0;
}
.btnPesquisa:hover:before {
  opacity: 1;
}

.btnPesquisa:focus:before {
  background-color: rgba(0, 0, 0,0.12);
  opacity: 1;
}

.navbar-scroll-custom-visivel {
  background-color: transparent;
  box-shadow: none;
}


.navbar-scroll-custom-visivel.navbar-light .navbar-nav .nav-link {
  color: ${(props) => props.infoJson.Cores.TextoTopoTransparente} ;
}

.navbar-light .navbar-nav .nav-link {
  stroke: ${(props) => props.infoJson.Cores.TextoTransparentScroll} ;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: ${({ theme }) => theme.text} ;
}
.menuMobile {  
  color: ${(props) =>
    props.infoJson.Cores.MenuResponsivo !== ""
      ? props.infoJson.Cores.MenuResponsivo
      : props.theme.text} ;
}
.menuMobile svg{  
  fill: ${(props) =>
    props.infoJson.Cores.MenuResponsivo !== ""
      ? props.infoJson.Cores.MenuResponsivo
      : props.theme.text} ;
}
.menuMobileCantact {  
color: ${(props) =>
  props.infoJson.Cores.MenuResponsivo !== ""
    ? props.infoJson.Cores.MenuResponsivo
    : props.theme.text} ;
stroke: ${(props) =>
  props.infoJson.Cores.MenuResponsivo !== ""
    ? props.infoJson.Cores.MenuResponsivo
    : props.theme.text} ;
}
.menuMobileCantact svg{ 
  height:26px;
}

.menuMobileCantact:hover,
.menuMobileCantact.active  {
  stroke: ${(props) => props.infoJson.Cores.HoverTopoTransparente};
  fill: ${(props) => props.infoJson.Cores.HoverTopoTransparente};
  color: ${(props) => props.infoJson.Cores.HoverTopoTransparente};
}
.dot{
  background-color: ${(props) => props.infoJson.Cores.Principal};
  background: -moz-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%, ${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: -webkit-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${(
    props
  ) => props.infoJson.Cores.GradienteInicio}', endColorstr='${(props) =>
  props.infoJson.Cores.GradienteFim}',GradientType=1 );
}
.dot::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255,0.12);
  mix-blend-mode: lighten;
  transition: opacity .35s ease-in-out;
  opacity: 0;
}
.dot:hover:before {
  opacity: 1;
}

.dot:focus:before {
  background-color: rgba(0, 0, 0,0.12);
  opacity: 1;
}

.dot-bg{
  background-color: ${({ theme }) => theme.boxCar};
  box-shadow: ${({ theme }) => theme.boxShadow};
  -webkit-box-shadow:${({ theme }) => theme.boxShadow};
  box-shadow: 0 13px 44px 0 rgba(0, 0, 0, 0.04);
}

.btnMaisFicha  {
  stroke: ${({ theme }) => theme.text} ;
  color: ${({ theme }) => theme.text} ;
}
.boxMenu  {
  stroke: ${({ theme }) => theme.text} ;
  color: ${({ theme }) => theme.text} ;
}

.boxMenu.disable  {
  fill:#A8A8A8;  
  stroke:#A8A8A8; 
  color: #A8A8A8 ; 
}
.boxMenu + span {
  background-image: linear-gradient(${(props) =>
    props.infoJson.Cores.HoverTopoTransparente}, ${(props) =>
  props.infoJson.Cores.HoverTopoTransparente});
}

.boxMenu:hover,
.boxMenu.active  {
  stroke: ${(props) => props.infoJson.Cores.HoverTopoTransparente};
  fill: ${(props) => props.infoJson.Cores.HoverTopoTransparente};
  color: ${(props) => props.infoJson.Cores.HoverTopoTransparente};
}

.navbar-scroll-custom-visivel .boxMenu{
  color:${({ theme }) => theme.text};
  fill:${({ theme }) => theme.text};
  stroke:${({ theme }) => theme.text};
}
.navbar-scroll-custom-visivel .menuMobile {
  color:#ffffff;
}
.navbar-scroll-custom-visivel .menuMobile svg {
  fill:#ffffff;
  stroke:#ffffff;
}
.navbar-scroll-custom-visivel .btnPesquisa + div {
  color:#ffffff;
}
.section-empresa{
  background-color: ${({ theme }) => theme.boxCar} !important;
}
.section-comparar{
  background-color: ${({ theme }) => theme.sectionComparar} !important;
}
.carousel__cell{
  background-color: ${({ theme }) => theme.body} !important;
}

.lineCarat > div > div > div:nth-child(1) {
  background-color: ${({ theme }) => theme.sectionComparar} !important;
}

.lineCarat > div > div > div {

  border-bottom: 2px solid ${({ theme }) => theme.sectionComparar} !important;
  background-color: ${({ theme }) => theme.boxCar} !important;
}

.offcanvas{
  background-color: ${({ theme }) => theme.bodyModal};
}

.btn-partilha{
  color: ${({ theme }) => theme.text};
}
.carousel-indicators .active {
  background-color: ${(props) => props.infoJson.Cores.Principal};
}
.boxMenuItem  {
  color: ${({ theme }) => theme.text};
  fill: ${({ theme }) => theme.text};
  stroke: ${({ theme }) => theme.text};
  border-bottom: 1px solid  ${({ theme }) => theme.lineOption};
}
.boxMenuItem:last-child  {
  border-bottom: none;
}
.boxMenuItem:hover,
.boxMenuItem.active  {
  color: ${(props) => props.infoJson.Cores.Principal};
  fill: ${(props) => props.infoJson.Cores.Principal};
  stroke: ${(props) => props.infoJson.Cores.Principal};
}
.btn_avancada{
  color: ${({ theme }) => theme.text};
  fill: ${({ theme }) => theme.text};
  stroke: ${({ theme }) => theme.text};
  border: 2px solid ${({ theme }) => theme.borderInput};  
  background-color: ${({ theme }) => theme.boxCar};
}
.dropdown-menu{
  background-color: ${(props) => props.infoJson.Cores.FundoTransparentScroll};
  color: ${({ theme }) => theme.text};
  box-shadow: 0 13px 44px 0 rgba(0,0,0,0.04);
}
.dropdown-item {
  color: ${({ theme }) => theme.text};
}
.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active {
  color: ${({ theme }) => theme.text};
  font-weight: 700;
  background-color: ${({ theme }) => theme.InputPesquisa};
  border-color: transparent;
  fill: ${({ theme }) => theme.text};
  stroke: ${({ theme }) => theme.text};
}
.nav-tabs .nav-item.nav-link, 
.nav-tabs .nav-link {
  color:  ${({ theme }) => theme.text};
  fill: ${({ theme }) => theme.text};
  stroke: ${({ theme }) => theme.text};
  font-weight: 700;
}
.nav-tabs {

  background-color: ${({ theme }) => theme.InputPesquisa};
  border-bottom: none;
}
.tab-content{
  background-color: ${({ theme }) => theme.InputPesquisa};
}
.nav-tabs .nav-item:last-child{
  margin-left:auto;
  }
.nav-tabs .nav-link .pesquisa-tab{
  color: ${({ theme }) => theme.dotsAtive};
  fill: ${({ theme }) => theme.dotsAtive};
  stroke: ${({ theme }) => theme.dotsAtive};
}

.nav-tabs .nav-link.active  .pesquisa-tab,
.nav-tabs .nav-link:hover  .pesquisa-tab{
  color: ${(props) => props.infoJson.Cores.Principal};
  fill: ${({ theme }) => theme.text};
  stroke: ${({ theme }) => theme.text};
}
.nav-tabs .nav-link.active  .pesquisa-tab > div:last-child,
.nav-tabs .nav-link:hover  .pesquisa-tab > div:last-child{
  background-color: ${(props) => props.infoJson.Cores.Principal};

  width: 60%;
}

@media (max-width: 767px) { 
  .nav-tabs .nav-item:last-child{
    margin-left: initial;
    }
}



.carousel-indicators [data-bs-target] {
  background-color: ${({ theme }) => theme.dots};
}

.frame-recentes::before{
background: linear-gradient(180deg,  ${(props) =>
  props.infoJson.Cores.GradienteInicio} 0%, ${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  -webkit-mask: linear-gradient(${({ theme }) =>
    theme.body} 0 0) content-box, linear-gradient(${({ theme }) =>
  theme.body} 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;

}

.section-recentes{
background-color: ${(props) => props.infoJson.Cores.Principal};
background-image: linear-gradient(180deg, ${({ theme }) => theme.body} 0%, ${({
  theme,
}) => theme.body}e8 100%);
box-shadow: 0 -6px 47px 31px rgba(0, 0, 0, 0.04);
}

.box-ligame{
  background-color: ${({ theme }) => theme.InputPesquisaLiga}; 
  border: 1.2px solid ${({ theme }) => theme.border} !important;
  color: ${({ theme }) => theme.text};  
}

.rectangle-tipos1  {
  border: 1.2px solid  ${({ theme }) => theme.border};
}
.rectangle-tipos1 > div:nth-child(1) {
  background-color: ${({ theme }) => theme.boxCar}ed;
}
.rectangle-tipos1 > div:nth-child(2) > div:nth-child(1) {
  fill: ${({ theme }) => theme.text};
}
.rectangle-tipos1 > div:nth-child(2) > div:nth-child(1) {
  color: ${(props) => props.infoJson.Cores.Principal};
}
.MenuFooter{

  -webkit-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.MenuFooter:hover{
  color: ${(props) => props.infoJson.Cores.Principal};

}
.rectangle-tipos2 > div:nth-child(1) {
  background-color: ${(props) => props.infoJson.Cores.Principal}ed;
}
.react-datetime-picker__inputGroup__input {
  color: ${({ theme }) => theme.text};
}
.viaturas-tipos a:hover {
  background-color: ${({ theme }) => theme.tiposHover}c4;
}
.btnTipo  {
  background-color: #fff; 
  color: ${(props) => props.infoJson.Cores.Principal} !important; 
}
.MultiValueWrapper-sc-1vzivtq-0{
  color:  ${({ theme }) => theme.btnPartilhaColor};
  fill:  ${({ theme }) => theme.btnPartilhaColor};
  stroke:  ${({ theme }) => theme.btnPartilhaColor};
  background-color: ${({ theme }) => theme.btnPartilhaBg};
}


.btnTipo:hover {
  -moz-box-shadow: inset 0 0 100px 100px rgba(0,0,0,0.12) !important;
  -webkit-box-shadow: inset 0 0 100px 100px rgb(0 0 0 / 12%) !important;
  box-shadow: inset 0 0 100px 100px rgb(0 0 0 / 12%) !important;
}
.btnTipo:focus {
  -moz-box-shadow: inset 0 0 100px 100px rgba(0,0,0,0.20) !important;
  -webkit-box-shadow: inset 0 0 100px 100px rgb(0 0 0 / 20%) !important;
  box-shadow: inset 0 0 100px 100px rgb(0 0 0 / 20%) !important;
}

.section-intervaloPreco{
  background-color: ${(props) => props.infoJson.Cores.Principal};
  background-image: linear-gradient(180deg, ${({ theme }) =>
    theme.body}e8 0%, ${({ theme }) => theme.body} 100%);
  box-shadow: 0 -6px 47px 31px rgba(0,0,0,0.04);
}

.rectangle-intervaloPreco {
  background-color: ${(props) => props.infoJson.Cores.Principal};
  box-shadow: 0 19px 38px 0 rgba(${(props) =>
    props.infoJson.Cores.RgbShadow},0.08);
    
}
.rectangle-intervaloPreco > div:nth-child(2) {
  background-image: linear-gradient(0deg, ${(props) =>
    props.infoJson.Cores.Principal} 25%, ${(props) =>
  props.infoJson.Cores.Principal}00 100%);
    
}
.rectangle-pontosVendas {
  border: 1.2px solid ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.boxCar};
  box-shadow: 0 13px 44px 0 rgba(0, 0, 0, 0.06);
}
.bg-servicos{
    background-color: ${({ theme }) => theme.boxCar};
    box-shadow: ${({ theme }) => theme.boxShadow};
    -webkit-box-shadow:${({ theme }) => theme.boxShadow};
    border: 1.2px solid  ${({ theme }) => theme.border};
    box-shadow: 0 13px 44px 0 rgba(0, 0, 0, 0.04);
}
.bg-servicos > div:first-child{

  background-image: linear-gradient(180deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio}91 0%, ${(props) =>
  props.infoJson.Cores.GradienteFim}91 100%);

}
.bg-servicos-round {
  background-image: linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%, ${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);

}
.svg-footer{
  stroke:${({ theme }) => theme.text};
}


.swiper-pagination-bullet-active {
  background-color : ${({ theme }) => theme.dotsAtive} !important;
}
.swiper-pagination-bullet {
  background-color : ${({ theme }) => theme.dots}!important ;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  background-color: ${({ theme }) => theme.boxCar};
  color: ${(props) => props.infoJson.Cores.Principal};
}
.form-floating > label {
  background-color: ${({ theme }) => theme.boxCar};
  color: ${(props) => props.infoJson.Cores.Principal};
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control::-webkit-input-placeholder {
  color: ${({ theme }) => theme.dotsAtive};
}

.interiorPage .form-control {
  box-shadow: none;
   background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  border: 2px solid ${({ theme }) => theme.linha};
  border-radius: 30px;
}

.interiorPage .form-control:focus {
  border-color: ${({ theme }) => theme.borderInput};
  background-color: ${({ theme }) => theme.body};
  box-shadow: ${({ theme }) => theme.boxShadow};
  outline: 0;
  color: ${({ theme }) => theme.text};
}

.interiorPage.form-floating > label,
.interiorPage .form-floating > label {
  background-color: ${({ theme }) => theme.body};
  color: ${(props) => props.infoJson.Cores.Principal};
}
.interiorPage.form-floating > .form-control:focus ~ label,
.interiorPage.form-floating > .form-control:not(:placeholder-shown) ~ label,
.interiorPage.form-floating > .form-select ~ label {
  background-color: ${({ theme }) => theme.body};
  color: ${(props) => props.infoJson.Cores.Principal};
}
.interiorPage .form-select {
  box-shadow: none;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  border: 2px solid ${({ theme }) => theme.linha};
  border-radius: 30px;
}
.titulo-Reduzido{
  color: ${(props) => props.infoJson.Cores.tituloPesquisa};
}

.titulo-Reduzido-interior{
  color: ${({ theme }) => theme.text};
}

.interiorPage.boxShadowPares {
  background-color: ${({ theme }) => theme.body};
  border: 2px solid ${({ theme }) => theme.linha};
}
.interiorPage.boxShadowPares > label {
  color: ${(props) => props.infoJson.Cores.Principal};
  background-color: ${({ theme }) => theme.body};
}


.boxCarat {
  background: linear-gradient(180deg, ${({ theme }) =>
    theme.boxCarMore} 0%, ${({ theme }) => theme.boxCar} 100%); 
}
.badged-rent{
  background-color: ${({ theme }) => theme.bgMore};
}

.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
  background-color: ${({ theme }) => theme.boxCar};
  box-shadow: ${({ theme }) => theme.boxShadow};
  -webkit-box-shadow:${({ theme }) => theme.boxShadow};
  border: 1.2px solid  ${({ theme }) => theme.border};
  border-radius: 38px;
}
.custom-calendar .rmdp-day.rmdp-today span {
  background-color:  ${({ theme }) => theme.boxCar};
  border: 2px solid  ${(props) => props.infoJson.Cores.Principal};
  color: ${(props) => props.infoJson.Cores.Principal};
}
.custom-calendar  .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color:  ${(props) => props.infoJson.Cores.Principal};
}


.custom-calendar .rmdp-range {
  background-color:  ${({ theme }) => theme.linha};
  color: ${({ theme }) => theme.text};
  box-shadow: none;
}
.custom-calendar .rmdp-header-values {
  color: ${(props) => props.infoJson.Cores.Principal};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;

}
.custom-calendar .rmdp-day, 
.custom-calendar .rmdp-week-day {
 color: ${({ theme }) => theme.text};
  height: 44px;
  width: 44px;
  margin-top:2px;
  margin-bottom:2px;
}

.custom-calendar .rmdp-range-hover.end, .rmdp-range.end .sd,
 .custom-calendar .rmdp-range-hover.start, .rmdp-range.start .sd {
  background-color:  ${(props) => props.infoJson.Cores.Principal}; 
  color: #ffffff;
}
.custom-calendar .rmdp-week-day {
  color: ${({ theme }) => theme.text};
  font-size: 16.8px;
  letter-spacing: 0;
  line-height: 21px;
}

.custom-calendar .rmdp-border-top{
  border-top: none;
}

.btnComparador{
  background-color: ${(props) => props.infoJson.Cores.Principal};
  background: -moz-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%, ${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: -webkit-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${(
    props
  ) => props.infoJson.Cores.GradienteInicio}', endColorstr='${(props) =>
  props.infoJson.Cores.GradienteFim}',GradientType=1 );
}
.btnComparador::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255,0.12);
  mix-blend-mode: lighten;
  transition: opacity .35s ease-in-out;
  opacity: 0;
}
.btnComparador:hover:before {
  opacity: 1;
}

.btnComparador:focus:before {
  background-color: rgba(0, 0, 0,0.12);
  opacity: 1;
}

.boxMenuMore li{
  border-bottom: 1px solid  ${({ theme }) => theme.lineOption};
}

.menu{
  border: 1px solid  ${({ theme }) => theme.lineOption};
  background: ${({ theme }) => theme.boxCar};
}
.menu.disable {
  border-color: transparent !important;
  box-shadow: none !important;
}
.menu.disable  > div{
  fill:#A8A8A8;  
  stroke:#A8A8A8; 
  color: #A8A8A8 ; 
}

.menu.active {
  border-color: ${({ theme }) => theme.lineOption} !important;
  box-shadow: 0 7px 9px 0 rgb(0 0 0 / 3%) !important;
}

.extra-rent-round{
  color: ${({ theme }) => theme.body};
  background-color:${({ theme }) => theme.text};

}
.boxShadowParesRent{
  background-color: #F4F4F4;
  border: 2px solid #F4F4F4;
}
.boxShadowParesRent.active{
  background-color: ${({ theme }) => theme.body};
  border: 2px solid ${(props) => props.infoJson.Cores.Principal};
}

.rectangle-ficha{
  background: ${({ theme }) => theme.body};
}

.rectangle-ficha.topo{
  background: ${({ theme }) => theme.boxCar};
}

.menu.active > div{
  fill:${(props) => props.infoJson.Cores.Principal};  
  stroke:${(props) => props.infoJson.Cores.Principal}; 
  color: ${(props) => props.infoJson.Cores.Principal} ; 
}
.close{
  color:${({ theme }) => theme.text};
  fill:${({ theme }) => theme.text};
  stroke:${({ theme }) => theme.text};
}
.form-select:disabled {
  background-color: ${({ theme }) => theme.disable}; 
}
::-webkit-scrollbar-track {
  background: ${({ theme }) => theme.boxCar};
}

::-webkit-scrollbar-track {
  border: 4px solid ${({ theme }) => theme.boxCar};
}

::-webkit-scrollbar {
  background-color:${({ theme }) => theme.boxCar};
  width: 10px;
}

.selectSvg {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18' height='10' viewBox='0 0 18 10'%3E%3Cdefs%3E%3Cfilter id='filter'  x='4750' y='705' width='18' height='10' filterUnits='userSpaceOnUse'%3E%3CfeFlood result='flood' flood-color=''/%3E%3CfeComposite result='composite' operator='in' in2='SourceGraphic'/%3E%3CfeBlend result='blend' in2='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cpath class='cls-1' fill='%23${(
    props
  ) =>
    props.infoJson.Cores.SVG.replace(
      "#",
      ""
    )}' d='M4768,706.263L4766.73,705l-7.74,7.509-0.83-.8v0l-6.88-6.67-1.28,1.244c1.9,1.844,7.21,6.992,8.99,8.713C4760.31,713.72,4759.02,714.967,4768,706.263Z' transform='translate(-4750 -705)'/%3E%3C/svg%3E%0A");
}


`;
