import axios from "axios";

const infoJson = async () => {
  try {
    const API_URL_TEXTOS = {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
      method: "GET",
      url: `/assets/json/info.json`,
    };

    const res = await axios.request(API_URL_TEXTOS);
    const textos = res.data;
    return textos;
  } catch (error) {
    console.error("error", error);
  }
};
const metaGetTags = async () => {
  try {
    var API_URL_TEXTOS = {
      method: "GET",
      url: `${window.location.protocol}//${window.location.host}/read/`,
      // url: `http://192.168.0.153:8000/read/`,
    };

    const res = await axios.request(API_URL_TEXTOS);
    const textos = res.data;
    return textos;
  } catch (error) {
    console.error("error", error);
  }
};

const metaPostTags = async (campos) => {
  try {
    var options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${window.location.protocol}//${window.location.host}/write/`,
      // url: `http://192.168.0.153:8000/write/`,
      data: campos,
    };

    return await axios
      .request(options)
      .then((error) => {
        return error;
      })
      .catch(function (error) {
        console.error(error);
      });
  } catch (error) {
    console.error("error", error);
  }
};

const createMetaTag = async (posts) => {
  try {
    const meta = await metaGetTags();

    const i = await meta.findIndex((_item) => {
      return _item.url === posts.url;
    });

    if (i > -1) meta[i] = posts; // (2)
    else meta.push(posts);

    await metaPostTags([...meta]);
  } catch (error) {
    console.log("error", error);
  }
};

export { infoJson, createMetaTag };
