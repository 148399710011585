import React from "react";
import { Link } from "react-router-dom";
import css from "./menuMobile.module.css";
import { ReactComponent as Bliner } from "../../../../assets/img/3-bliner/3bliner.svg";
import { ReactComponent as BlinerDark } from "../../../../assets/img/3-bliner/3bliner_dark.svg";

export default function MenuFooter({ allinfo, infoJson, allinfoText }) {
  return (
    <div className="col p-3">
      <div className={css.menuMobileCopyright}>
        {allinfo.NomeEmpresa && allinfo.Nif ? (
          <>
            {allinfo.NomeEmpresa} | NIF:{allinfo.Nif} |{"  "}
          </>
        ) : (
          <>
            {infoJson.Stand.Nome} |{"  "}
          </>
        )}
        Copyright © {new Date().getFullYear()} &nbsp;
      </div>
      <div className={css.menuMobileTexto}>
        {allinfoText.TextLegalGarantia &&
          allinfoText.TextLegalGarantia.Url !== "" && (
            <>
              <Link
                to={allinfoText.TextLegalGarantia.Url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {allinfoText.TextLegalGarantia.Titulo}
                <br></br>
              </Link>
            </>
          )}

        <Link to="/privacidade/" target="_blank" rel="noopener noreferrer">
          Resolução de Conflitos<br></br>Politica de Privacidade <br></br>
        </Link>

        {infoJson.Stand.LivroReclamacoes && (
          <a
            href="https://www.livroreclamacoes.pt/inicio"
            rel="noopener noreferrer"
            target="_blank"
          >
            Livro de reclamações<br></br>
          </a>
        )}
        <div className={css.btnEasysite}>
          {infoJson.Layout.ThemeDark ? <BlinerDark /> : <Bliner />} um original{" "}
          <a
            href="http://www.easysite.pt"
            target="_blank"
            rel="noopener noreferrer"
            className={css.btnEasysite}
          >
            {"\u00A0"}
            Easysite
          </a>
        </div>
      </div>
    </div>
  );
}
