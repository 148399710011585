import React, { useEffect } from "react";
import { useLocation } from "react-router";
import Banners from "./banner/Banners";
import { createMetaTags } from "../../helpers/tratarInfo";
import * as apiJson from "../../../api/apiJson";
import SectionList from "./SectionList";
import { ReactComponent as Seta } from "../../../assets/icons/seta_para_baixo.svg";
import css from "./home.module.css";
import PopUp from "./popup/PopUp";
import VideoBanner from "./banner/VideoBanner";

export default function Home({ infoJson, setMetaTags, allinfoText }) {
  const location = useLocation();

  useEffect(() => {
    let Morada;
    if (infoJson.Stand.Distrito === infoJson.Stand.Concelho) {
      Morada = infoJson.Stand.Distrito;
    }
    if (infoJson.Stand.Distrito !== infoJson.Stand.Concelho) {
      Morada = infoJson.Stand.Concelho + ", " + infoJson.Stand.Distrito;
    }

    const description =
      `Procura automóvel? Escolha com Confiança. Stand de Carros Usados e Seminovos.` +
      ` Vendemos viaturas de qualidade para todo o país. Visite-nos em ${Morada}!`;

    const tags = [
      `carros usados ${infoJson.Stand.Distrito}`,
      `stand usados ${infoJson.Stand.Distrito}`,
      `automoveis usados ${infoJson.Stand.Distrito}`,
      "stand semi-novos",
      "viaturas usadas",
      `Stands ${infoJson.Stand.Distrito}`,
    ];

    //Listagem
    const title = `${infoJson.Stand.Nome} - Rent a car em  ${Morada}`;

    const metatags = createMetaTags(
      infoJson.Stand.Nome,
      "",
      tags,
      description,
      Morada,
      infoJson.Stand.Website,
      title
    );

    apiJson.createMetaTag({
      url: location.pathname,
      title: title,
      description: description,
      thumbnail: infoJson.Stand.Website + "/assets/img/partilha_fb.jpg",
    });

    setMetaTags(metatags);
  }, [
    infoJson.Stand.Concelho,
    infoJson.Stand.Distrito,
    infoJson.Stand.Nome,
    infoJson.Stand.Website,
    setMetaTags,
    location.pathname,
  ]);

  return (
    <>
      {infoJson.Stand.PopUp.Existe && <PopUp infoJson={infoJson} />}
      {infoJson.Banner.Existe && <Banners infoJson={infoJson} />}
      {infoJson.Banner.Video.Existe && <VideoBanner infoJson={infoJson} />}
      <div className={`${css.titulo_reserva}`}>
        Faça aqui a sua reserva{" "}
        <Seta
          style={{
            marginTop: "10px",
          }}
        />
      </div>

      <section>
        <SectionList infoJson={infoJson} allinfoText={allinfoText} />
      </section>
    </>
  );
}
