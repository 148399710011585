import React from "react";
import css from "./siteholder.module.css";
const logo = "/assets/img/logo.webp";

export default function Siteholder({ infoJson }) {
  return (
    <div
      className={`${css.box} ${
        infoJson.Layout.ThemeDark ? css.black : css.white
      }`}
    >
      <img src={logo} className={` ${css.img}`} alt={infoJson.Stand.Nome} />
    </div>
  );
}
