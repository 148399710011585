import React, { lazy, Suspense } from "react";

const EmpresaHome = lazy(() => import("./empresa/EmpresaHome"));
const ListagemRentHome = lazy(() => import("../rent/ListagemRentHome"));

export default function SectionList({ infoJson, allinfoText }) {
  const renderSwitch = (param) => {
    switch (param) {
      case "QuemSomos":
        return (
          <>
            {infoJson.Layout.QuemSomos.existe && (
              <>
                <EmpresaHome infoJson={infoJson} allinfoText={allinfoText} />
              </>
            )}
          </>
        );
      case "Listagem":
        return (
          <>
            {infoJson.Layout.QuemSomos.existe && (
              <>
                <ListagemRentHome
                  infoJson={infoJson}
                  allinfoText={allinfoText}
                />
              </>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Suspense fallback={<></>}>
        {infoJson.Home.sort((a, b) => a.ordenador - b.ordenador).map((item) => {
          return (
            <React.Fragment key={item.id}>
              {renderSwitch(item.nome)}
            </React.Fragment>
          );
        })}
      </Suspense>
    </>
  );
}
