import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import cssModal from "../../../modal/modalPartilhar.module.css";
import { ReactComponent as Fechar } from "../../../../assets/icons/modal/fechar.svg";
import { useCookies } from "react-cookie";

export default function PopUp({ infoJson }) {
  const [show, setShow] = useState(true);
  const [cookies, setCookie] = useCookies(["cookie-popUp"]);

  useEffect(() => {
    if (cookies.popUp) {
      setShow(false);
    }
  }, [cookies.popUp]);

  const onDismiss = () => {
    setShow(false);
    if (infoJson.Stand.PopUp.Cookie) {
      setCookie("popUp", true, { maxAge: 86400 });
    }
  };
  return (
    <>
      {!cookies.popUp && (
        <Modal centered show={show} size="lg" fullscreen={"md-down"}>
          <Modal.Body>
            <div
              onClick={() => onDismiss()}
              className={` ${cssModal.close} close`}
            >
              <Fechar />
            </div>
            <img
              className="w-100 h-auto"
              src={infoJson.Stand.PopUp.Src}
              alt="pop up"
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
