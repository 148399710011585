import axios from "axios";

const global = require("../config/config");

async function getInfoTextos(infoJson) {
  try {
    const API_URL_TEXTOS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetInfoTextos/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_TEXTOS);
    const textos = res.data;
    return textos;
  } catch (error) {
    console.log("error", error);
  }
}

async function getBanner(codBanner, infoJson) {
  try {
    const API_URL_BANNERS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetBanner/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
        zona_id: codBanner,
        numero: infoJson.Banner.Top,
      },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_BANNERS);

    if (res.data.length > 0) {
      const banners = res.data;
      return banners;
    }
    return [];
  } catch (error) {
    console.log("error", error);
  }
}

async function getHorarios(CodPontoVenda, Concelho, infoJson) {
  try {
    const API_URl_HORARIOS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetHorario/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
        stand_id: CodPontoVenda,
        country: Concelho,
      },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URl_HORARIOS);
    const servico = res.data;

    return servico;
  } catch (error) {
    console.log("error", error);
  }
}

async function getPrivacidade(infoJson) {
  try {
    const API_URl_INTERVALO = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/privacidade/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
      },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URl_INTERVALO);
    const intervalo = res.data;

    return intervalo;
  } catch (error) {
    console.log("error", error);
  }
}
export {

  getBanner,
  getHorarios,
  getInfoTextos,
  getPrivacidade,
 
};
