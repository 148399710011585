import { tratarValorMensalidade } from "./tratarInfoCarros";

function tratarCombustivelRent(data) {
  try {
    let arr = [];

    data.forEach((element) => {
      arr.push(element.Nome);
    });

    return arr.toString().replaceAll(",", "/");
  } catch (error) {
    console.log("tratarCombustivelRent - error", error);
    return "";
  }
}

function tratarPrecoRent(Preco) {
  try {
    const ValorMensalidadeLimpo = tratarValorMensalidade(Preco);

    return ValorMensalidadeLimpo;
  } catch (error) {
    console.log("tratarPrecoRent - error", error);
    return "";
  }
}

function SplitValueRent(value) {
  try {
    // split do vaor
    let myArray;
    if (value !== "" && value !== 0) {
      if (value.includes(",")) {
        myArray = value.split(",");
      } else {
        myArray = [value, ""];
      }
    } else {
      myArray = ["", ""];
    }

    return myArray;
  } catch (error) {
    console.log("SplitValueRent - error", error);
    return ["", ""];
  }
}

function createDate(value) {
  try {
    if (value) {
      let now = new Date(value).toLocaleDateString("pt-pt", {
        weekday: "long",
        month: "long",
        day: "numeric",
      });

      return now;
    } else {
      return "";
    }
  } catch (error) {
    console.log("createDate - error", error);
    return "";
  }
}

const tratarPrecoSelecionado = (dias, min, max) => {
  try {
    if (parseInt(dias) * parseFloat(min) > parseFloat(max)) {
      return parseFloat(max);
    }
    return parseInt(dias) * parseFloat(min);
  } catch (error) {
    console.log("error", error);
    return 0;
  }
};
const SelectPlanoRent = (planos, dias) => {
  try {
    if (!dias) {
      const planoComPrecoMaisBaixo = planos.reduce((planoAtual, plano) => {
        return plano.Preco < planoAtual.Preco ? plano : planoAtual;
      }, planos[0]);

      return parseFloat(planoComPrecoMaisBaixo.Preco);
    }

    if (parseInt(dias) <= 2) {
      return parseFloat(planos[0].Preco) * parseInt(dias);
    }
    if (parseInt(dias) >= 3 && parseInt(dias) <= 10) {
      return parseFloat(planos[1].Preco) * parseInt(dias);
    }

    if (parseInt(dias) >= 11 && parseInt(dias) <= 30) {
      return parseFloat(planos[2].Preco) * parseInt(dias);
    }

    if (parseInt(dias) >= 31 && parseInt(dias) <= 180) {
      return parseFloat(planos[3].Preco) * parseInt(dias);
    }
    if (parseInt(dias) > 180) {
      return parseFloat(planos[4].Preco) * parseInt(dias);
    }

    const planoComPrecoMaisBaixo = planos.reduce((planoAtual, plano) => {
      return plano.Preco < planoAtual.Preco ? plano : planoAtual;
    }, planos[0]);

    return parseFloat(planoComPrecoMaisBaixo.Preco);
  } catch (error) {
    console.log("error", error);
    return 0;
  }
};

const ValorTotalRent = (
  preco,
  acessorios,
  precoIdade,
  PrecoforaHorasLevantamento,
  PrecoforaHorasDevolucao
) => {
  try {
    let totalPreco = 0;

    if (Number.isInteger(parseInt(preco))) {
      totalPreco = parseFloat(totalPreco) + parseFloat(preco);
    }

    if (Number.isInteger(parseInt(precoIdade))) {
      totalPreco = parseFloat(totalPreco) + parseFloat(precoIdade);
    }

    if (Number.isInteger(parseInt(PrecoforaHorasLevantamento))) {
      totalPreco =
        parseFloat(totalPreco) + parseFloat(PrecoforaHorasLevantamento);
    }

    if (Number.isInteger(parseInt(PrecoforaHorasDevolucao))) {
      totalPreco = parseFloat(totalPreco) + parseFloat(PrecoforaHorasDevolucao);
    }

    acessorios
      .filter((item) => item.quantidade > 0)
      .forEach((item) => {
        totalPreco =
          parseFloat(totalPreco) +
          parseFloat(item.preco) * parseFloat(item.quantidade);
      });

    return totalPreco;
  } catch (error) {
    console.log("error", error);

    return 0;
  }
};

const ordenarSegmentos = (array, tipo) => {
  switch (tipo) {
    case "precoAsc":
      return array.sort((a, b) => {
        const precoA = a.Planos.reduce((planoAtual, plano) => {
          return plano.Preco < planoAtual.Preco ? plano : planoAtual;
        }, a.Planos[0]);

        const precoB = b.Planos.reduce((planoAtual, plano) => {
          return plano.Preco < planoAtual.Preco ? plano : planoAtual;
        }, b.Planos[0]);

        return precoA.Preco - precoB.Preco;
      });

    case "precoDesc":
      return array.sort((a, b) => {
        const precoA = a.Planos.reduce((planoAtual, plano) => {
          return plano.Preco < planoAtual.Preco ? plano : planoAtual;
        }, a.Planos[0]);

        const precoB = b.Planos.reduce((planoAtual, plano) => {
          return plano.Preco < planoAtual.Preco ? plano : planoAtual;
        }, b.Planos[0]);

        return precoB.Preco - precoA.Preco;
      });

    case "nomeAsc":
      return array.sort((a, b) => {
        const nomeA = a.Nome.toLowerCase();
        const nomeB = b.Nome.toLowerCase();

        if (nomeA < nomeB) {
          return -1;
        }
        if (nomeA > nomeB) {
          return 1;
        }
        return 0;
      });

    case "nomeDesc":
      return array.sort((a, b) => {
        const nomeA = a.Nome.toLowerCase();
        const nomeB = b.Nome.toLowerCase();

        if (nomeA > nomeB) {
          return -1;
        }
        if (nomeA < nomeB) {
          return 1;
        }
        return 0;
      });

    default:
      return array;
  }
};

export {
  tratarCombustivelRent,
  tratarPrecoRent,
  SplitValueRent,
  createDate,
  tratarPrecoSelecionado,
  SelectPlanoRent,
  ValorTotalRent,
  ordenarSegmentos,
};
