import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Wrapper } from "./components/Wrapper";
import Home from "../pages/home/Home";
import { Spinner } from "react-bootstrap";
import PageHolder from "../tools/placeholder/PageHolder";
import Layout from "../Tpl/Layout";
import AuthContext from "../hooks/AuthContext";
import { RentProvider } from "../pages/rent/reting/RentContext";

const ModalNewsletter = lazy(() => import("../modal/ModalNewsletter"));
const Privacidade = lazy(() => import("../pages/privacidade/Privacidade"));
const Erro = lazy(() => import("../pages/erro/Erro"));
const Obrigado = lazy(() => import("../pages/obrigado/Obrigado"));
const Empresa = lazy(() => import("../pages/empresa/Empresa"));
const Contactos = lazy(() => import("../pages/contactos/Contactos"));
const Reting = lazy(() => import("../pages/rent/reting/Reting"));
const Profissionais = lazy(() =>
  import("../pages/rent/profissionais/Profissionais")
);
const Faq = lazy(() => import("../pages/rent/faq/Faq"));
const ListagemRent = lazy(() => import("../pages/rent/ListagemRent"));

const renderPaths = (paths, Element) =>
  paths.map((path) => <Route key={path} path={path} element={Element} />);

export default function Rotas({ setMetaTags, infoJson, allinfoText }) {
  const { state } = React.useContext(AuthContext);

  return (
    <>
      <Wrapper>
        <Routes>
          <Route
            element={<Layout infoJson={infoJson} allinfoText={allinfoText} />}
          >
            <Route
              path="/"
              element={
                <>
                  <Home
                    infoJson={infoJson}
                    setMetaTags={setMetaTags}
                    allinfoText={allinfoText}
                  />
                </>
              }
            />

            {infoJson.RentEmpresa.existe && (
              <Route
                path={
                  allinfoText.RentEmpresa && allinfoText.RentEmpresa.Url !== ""
                    ? allinfoText.RentEmpresa.Url
                    : infoJson.RentEmpresa.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Empresa
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />{" "}
                  </Suspense>
                }
              />
            )}

            {infoJson.RentProfissionais.existe && (
              <Route
                path={
                  allinfoText.RentProfissionais &&
                  allinfoText.RentProfissionais.Url !== ""
                    ? allinfoText.RentProfissionais.Url
                    : infoJson.RentProfissionais.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Profissionais
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />{" "}
                  </Suspense>
                }
              />
            )}

            {infoJson.RentFaq.existe && (
              <Route
                path={
                  allinfoText.RentFaq && allinfoText.RentFaq.Url !== ""
                    ? allinfoText.RentFaq.Url
                    : infoJson.RentFaq.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Faq
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />{" "}
                  </Suspense>
                }
              />
            )}

            {infoJson.Rent.existe && (
              <Route
                path={
                  allinfoText.Rent && allinfoText.Rent.Url !== ""
                    ? allinfoText.Rent.Url
                    : infoJson.Rent.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <ListagemRent
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />{" "}
                  </Suspense>
                }
              />
            )}

            {infoJson.Rent.existe && (
              <Route
                path={`${
                  allinfoText.Rent && allinfoText.Rent.Url !== ""
                    ? allinfoText.Rent.Url
                    : infoJson.Rent.url
                }:codRent/*`}
                element={
                  <Suspense fallback={<PageHolder />}>
                    <RentProvider>
                      <Reting
                        infoJson={infoJson}
                        setMetaTags={setMetaTags}
                        allinfoText={allinfoText}
                      />
                    </RentProvider>
                  </Suspense>
                }
              />
            )}

            {infoJson.Contactos.existe && (
              <Route
                path={
                  allinfoText.Contactos && allinfoText.Contactos.Url !== ""
                    ? allinfoText.Contactos.Url
                    : infoJson.Contactos.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Contactos
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}

            <Route
              path={"/privacidade/"}
              element={
                <Suspense fallback={<PageHolder />}>
                  <Privacidade infoJson={infoJson} setMetaTags={setMetaTags} />
                </Suspense>
              }
            />

            {renderPaths(
              ["/obrigado"],

              <Suspense
                fallback={
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner animation="border" />
                  </div>
                }
              >
                <Obrigado setMetaTags={setMetaTags} infoJson={infoJson} />
              </Suspense>
            )}

            {state.infoPrincipal.length !== 0 &&
              state.infoPrincipal[0].EmailNewsletter && (
                <>
                  <Route
                    path="/newsletter"
                    element={
                      <Suspense
                        fallback={
                          <div className="d-flex align-items-center justify-content-center">
                            <Spinner animation="border" />
                          </div>
                        }
                      >
                        <ModalNewsletter
                          infoJson={infoJson}
                          setMetaTags={setMetaTags}
                          infoPrincipal={state.infoPrincipal}
                        />
                      </Suspense>
                    }
                  />
                </>
              )}

            {renderPaths(
              [`*`, `erro`],
              <Suspense
                fallback={
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner animation="border" />
                  </div>
                }
              >
                <Erro setMetaTags={setMetaTags} infoJson={infoJson} />
              </Suspense>
            )}
          </Route>
        </Routes>
      </Wrapper>
    </>
  );
}
