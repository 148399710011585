import React from "react";
import Spacer from "../Spacer";
import css from "./banners.module.css";
import ReactPlayer from "react-player/youtube";

export default function VideoBanner({ infoJson }) {
  return (
    <>
      <Spacer height="85px" clasName="d-lg-none" />

      <ReactPlayer
        className={css.bannerVideo}
        width="100%"
        height="100%"
        url={infoJson.Banner.Video.Src}
        playing={true}
        controls={false}
        loop={true}
        volume={0}
        muted={true}
        config={{
          youtube: {
            playerVars: { rel: 0, showinfo: 0 },
          },
        }}
      />
    </>
  );
}
